<!--
 * @Description: 创建课程
 * @Author: xiawenlong
 * @Date: 2021-04-26 09:09:58
 * @LastEditors: xiawenlong
 * @LastEditTime: 2022-12-08 10:18:26
-->

<template>
  <div class="course-library-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ courseId ? '编辑课程' : '创建课程' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="115px" :rules="rules">
        <el-form-item label="课程名称" prop="courseName">
          <el-input
            v-model="form.courseName"
            placeholder="请输入2-32个汉字课程名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="课程特点" prop="introduction">
          <el-input
            v-model="form.introduction"
            placeholder="请输入6-251个汉字"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="授课形式" prop="courseType">
          <el-radio-group v-model="form.courseType" :disabled="courseId ? true : false">
            <el-radio :label="0">录播课</el-radio>
            <el-radio :label="1">直播课</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="授课讲师" :prop="teacherType == 0 ? 'teacherId' : 'teacherName'">
          <el-radio-group v-model="teacherType">
            <el-radio :label="0">平台内讲师</el-radio>
            <el-radio :label="1">其他讲师</el-radio>
          </el-radio-group>
          <div>
            <el-select
              v-if="teacherType == 0"
              v-model="form.teacherId"
              filterable
              placeholder="请输入关键词"
              value-key="teacherId"
              clearable
              @change="teacherTypeChange"
            >
              <el-option
                v-for="option in teacherList"
                :key="option.teacherId"
                :value="option"
                :label="option.teacherName"
              >
              </el-option>
            </el-select>
            <el-input
              v-if="teacherType == 1"
              v-model="form.teacherName"
              placeholder="请输入授课讲师姓名"
              clearable
            >
            </el-input>
          </div>
        </el-form-item>
        <el-form-item ref="pic" label="课程封面图" prop="pic">
          <upload-image
            v-model="form.pic"
            :upload-img="$refs.pic"
            :width-size="widthSize"
            :height-size="heightSize"
          ></upload-image>
          <p class="tip">图片大小最好在564 X 290，支持扩展名：.png .jpg .jpeg</p>
        </el-form-item>
        <el-form-item ref="attachments" label="课程课件">
          <!-- <el-upload drag action="" multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
          </el-upload> -->
          <upload-file v-model="form.attachments"> </upload-file>
        </el-form-item>
        <el-form-item label="课程详细描述">
          <tinymce ref="editor" v-model="form.summary"></tinymce>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="submit">{{
            courseId ? '确定修改' : '创建并添加章节'
          }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { courseCreate, courseInfo, courseUpdate } from '@/api/course'
import { orgTeacherList } from '@/api/teacher'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
import UploadFile from '@/components/UploadFile'
import Tinymce from '@/components/Tinymce'
export default {
  name: 'CourseLibraryAdd',
  components: { UploadImage, UploadFile, Tinymce },
  data() {
    return {
      widthSize: 564,
      heightSize: 290,
      rules: {
        courseName: [
          { required: true, message: '请输入课程名称', trigger: 'blur' },
          { min: 2, max: 32, message: '请输入2~32个字', trigger: 'blur' },
        ],
        introduction: [
          { required: true, message: '请输入课程特点', trigger: 'blur' },
          { min: 6, max: 251, message: '请输入6~251个字', trigger: 'blur' },
        ],
        courseType: [{ required: true, message: '请选择课程类型', trigger: 'change' }],
        // teacherName: [{ required: true, message: '请输入授课讲师', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传课程封面图', trigger: 'change' }],
        teacherId: [{ required: true, message: '请选择平台讲师', trigger: 'change' }],
      },
      form: {
        courseType: 0,
        teacherId: '',
      },
      loading: false,
      courseId: '',
      teacherType: 0,
      teacherList: [],
      teacherId: '',
      currentTeacher: null,
    }
  },
  mounted() {
    const { courseId } = this.$route.params
    this.courseId = courseId
    if (courseId) this.getCourseInfo()
    this.orgTeacherList()
  },
  methods: {
    async orgTeacherList() {
      const [res, err] = await to(orgTeacherList({ keyword: '' }))
      if (err) return this.$message.warning(err.msg)
      this.teacherList = res.data
    },
    async getCourseInfo() {
      const [res, err] = await to(courseInfo({ courseId: this.courseId }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
      if (
        res.data.teacherList != undefined &&
        res.data.teacherList != null &&
        res.data.teacherList.length > 0
      ) {
        this.teacherType = 0
        this.teacherId = res.data.teacherList[0].teacherName
        this.currentTeacher = res.data.teacherList[0]
        this.form.teacherId = res.data.teacherList[0]
      } else {
        this.teacherType = 1
      }
    },
    teacherTypeChange(e) {
      this.$forceUpdate()
      this.currentTeacher = e
      this.form.teacherId = e
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          if (this.teacherType == 0) {
            this.form.teacherIds = []
            this.form.teacherIds.push(this.currentTeacher.teacherId)
            this.form.teacherName = ''
          } else {
            this.form.teacherIds = []
          }
          this.courseId ? this.update() : this.create()
        }
      })
    },
    async create() {
      this.loading = true
      const [, err] = await to(courseCreate(this.form))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('创建成功')
      this.$router.push('/course/library')
    },
    async update() {
      this.loading = true
      const [, err] = await to(courseUpdate({ ...this.form, courseId: this.courseId }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('修改成功')
      this.$router.push('/course/library')
    },
  },
}
</script>
<style lang="scss" scoped>
.course-library-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
}
</style>
